import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import {
  getInvoiceItem,
  getServices,
  getSpecificInvoice,
} from "../../actions/invoiceItem";
import { getInvoiceMeasurement } from "../../actions/measurment";
import { getBusinessProfile } from "../../actions/settings";
import { getSpecificLocation } from "../../actions/warehouseAction";

import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Button,
  Col,
  Row,
  Select,
  message,
  TreeSelect,
  Space,
  Divider,
  Drawer,
  Image,
  Skeleton,
} from "antd";

const { Option } = Select;

const Quickview = ({
  id,
  getInvoiceItem,
  getServices,
  getBusinessProfile,
  businessprofile,
  getInvoiceMeasurement,
  getSpecificLocation,
  getSpecificInvoice,
}) => {
  var formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });
  const componentRef = useRef();
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);
  const [measurements, setmeasurements] = useState([]);
  const [details, setdetails] = useState([]);
  const [branch, setbranch] = useState([]);
  const [services, setservices] = useState([]);
  const count = useRef(0);
  const serialcount = useRef(0);

  const showDrawer = () => {
    getSpecificInvoice(id).then((r) => {
      setdetails(r);
      getSpecificLocation(r.location).then((result) => {
        setbranch(result);
      });
    });

    getInvoiceItem(id).then((e) => {
      getBusinessProfile();
      getServices(id).then((service) => {
        getInvoiceMeasurement(id).then((result) => {
          console.log(result);
          if (result.length > 0) {
            setmeasurements(result);
          } else {
            // setmeasurements(false);
          }
          count.current = e.length + service.length;
          if (count.current < 10) count.current = 10 - count.current;
          else {
            count.current = 0;
          }
          setdata([...e, ...service]);

          setloading(false);
          setservices(service);
        });
      });
    });
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const renderImage = () => {
    if (loading) {
      return <Skeleton active />;
    } else {
      return (
        <img
          src={branch.logo}
          style={{
            // width: "100%",
            right: "0",
            maxHeight: "60px"
          }}
        />
      );
    }
  };
  const renderitems = () => {
    if (loading) {
      return <Skeleton active />;
    } else {
      return data.map((item, index) => {
        // console.log(item);
        serialcount.current = index + 1;
        return (
          <>
            <tr>
              <td>{index + 1}</td>
              {item.details ? (
                <td>
                  {" "}
                  <div
                    className="d-div"
                    dangerouslySetInnerHTML={{ __html: item.details }}
                  ></div>
                </td>
              ) : (
                <td>
                  {" "}
                  {item.Details}
                  <small>
                    {"  ("}
                    {item.Product[0].color ? item.Product[0].color + " / " : ""}
                    {item.Product[0].size ? +item.Product[0].size : ""}
                    {")"}
                  </small>
                </td>
              )}
              {item.details ? (
                <td></td>
              ) : (
                <td> {item.Product[0].Deatils[0].product_code}</td>
              )}

              <td style={{ textAlign: "center", margin: "auto" }}>
                {item.quantity}
              </td>
            </tr>
          </>
        );
      });
    }
  };

  const renderData = () => {
    if (loading) {
      return <Skeleton active />;
    } else {
      return (
        <>
          <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <div
              className="invoice_print_fontSize"
              ref={componentRef}
              style={{ padding: "10px" }}
            >
              <Row
                className="invoice_print_fontSize"
                style={{
                  borderBottom: "2px solid lightgray",
                  paddingBottom: "5px",
                }}
              >
                <Col
                  span={10}
                  style={{
                    paddingTop: "45px",
                  }}
                >
                  INVOICE NO.{" "}
                  {details.invoice_number ? (
                    <b>{details.invoice_number}</b>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  {renderImage()}
                </Col>
                <Col
                  span={10}
                  style={{ textAlign: "right", paddingTop: "25px" }}
                >
                  {details.Contact[0].name ? (
                    <> {details.Contact[0].name}</>
                  ) : (
                    ""
                  )}
                  <br></br>
                  ORDER NO.{" "}
                  {details.order_number ? <b>{details.order_number}</b> : ""}
                  <br></br>
                  DELIVERY - <b>{details.delivery_date}</b>
                  { }
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col span={18}>
                  <table className="product_table invoice_print_fontSize">
                    <tbody>
                      <tr style={{ fontWeight: "500" }}>
                        <td>NO</td>
                        <td>PRODUCT DETAILS</td>
                        <td>DESIGN CODE</td>

                        <td>QTY</td>
                      </tr>
                      {renderitems()}
                    </tbody>
                  </table>
                </Col>
                <Col span={6}>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} style={{ marginTop: "5px" }}>
                      DELIVERY DATE
                      <span className="order_span">
                        {details.delivery_date ? details.delivery_date : "-"}
                      </span>
                    </Col>
                    <Col span={24} style={{ marginTop: "5px" }}>
                      PROGRAM DATE
                      <span className="order_span">
                        {details.program_date ? details.program_date : "-"}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              {measurements?.map((measurement) => {
                return (
                  <>
                    <Row>
                      <Col span={8}>
                        <Row>
                          <Col span={24}>
                            <h3>EXTRA ADDITIONS</h3>
                            <div
                              style={{
                                minHeight: "40vh",
                                border: "2px solid black",
                                padding: "10px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: measurement.Note,
                              }}
                            ></div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={16} style={{ paddingLeft: "10px" }}>
                        <h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: measurement.measuremnt_name,
                            }}
                          ></div>
                          MEASUREMENT DETAILS{" "}
                        </h3>
                        <Row
                          style={{
                            border: "2px solid black",
                            padding: "10px",
                          }}
                        >
                          <Col
                            span={15}
                            style={{ borderRight: "1px solid lightgray" }}
                          >
                            <h5>TOPS</h5>
                            {measurement.Blouse ? (
                              <Checkbox checked={true} disabled />
                            ) : (
                              <Checkbox checked={false} disabled />
                            )}{" "}
                            ব্লাউজ
                            {measurement.Kameez ? (
                              <Checkbox checked={true} disabled />
                            ) : (
                              <Checkbox checked={false} disabled />
                            )}{" "}
                            কামিজ
                            {measurement.Gown ? (
                              <Checkbox checked={true} disabled />
                            ) : (
                              <Checkbox checked={false} disabled />
                            )}{" "}
                            গাউন
                            <br></br>
                            <br></br>
                            <Row>
                              <Col span={11}>
                                <table>
                                  <tr>
                                    <td>বডি :</td>
                                    <td className="td_dotted1">
                                      {measurement.Chest
                                        ? measurement.Chest
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>কোমর :</td>
                                    <td className="td_dotted1">
                                      {measurement.Waist
                                        ? measurement.Waist
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>হিপ :</td>
                                    <td className="td_dotted1">
                                      {measurement.Hip ? measurement.Hip : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>কামিজ লম্বা :</td>
                                    <td className="td_dotted1">
                                      {measurement.End ? measurement.End : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>সোল্ডার :</td>
                                    <td className="td_dotted1">
                                      {measurement.Shoulder
                                        ? measurement.Shoulder
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>আর্মহোল :</td>
                                    <td className="td_dotted1">
                                      {measurement.Arm_hole
                                        ? measurement.Arm_hole
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td> হাতা লম্বা :</td>
                                    <td className="td_dotted1">
                                      {measurement.Sleeve_l
                                        ? measurement.Sleeve_l
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td> মাসেল :</td>
                                    <td className="td_dotted1">
                                      {measurement.Muscle
                                        ? measurement.Muscle
                                        : ""}
                                    </td>
                                  </tr>
                                </table>
                              </Col>
                              <Col span={13}>
                                <br></br>
                                <table className="2nd_td_dotted">
                                  <tr>
                                    <td>মুহরি :</td>
                                    <td className="td_dotted">
                                      {measurement.Hand_opening
                                        ? measurement.Hand_opening
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>লম্বা :</td>
                                    <td className="td_dotted">
                                      {measurement.Length
                                        ? measurement.Length
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>কামিজ ফাড়া:</td>
                                    <td className="td_dotted">
                                      {measurement.Slit ? measurement.Slit : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>গলার ডিপ (F) :</td>
                                    <td className="td_dotted">
                                      {measurement.Neck_deep_f
                                        ? measurement.Neck_deep_f
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>গলার ডিপ (B) :</td>
                                    <td className="td_dotted">
                                      {measurement.Neck_deep_b
                                        ? measurement.Neck_deep_b
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>হাফ বডি</td>
                                    <td className="td_dotted">
                                      {measurement.Half_body
                                        ? measurement.Half_body
                                        : ""}
                                    </td>
                                  </tr>
                                </table>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8} offset={1}>
                            <h5>Bottom</h5>
                            {measurement.Skirt ? (
                              <Checkbox checked={true} disabled />
                            ) : (
                              <Checkbox checked={false} disabled />
                            )}{" "}
                            স্কার্ট
                            {measurement.Paladzo ? (
                              <Checkbox checked={true} disabled />
                            ) : (
                              <Checkbox checked={false} disabled />
                            )}{" "}
                            প্লাজো
                            <br></br>
                            {measurement.Pant ? (
                              <Checkbox checked={true} disabled />
                            ) : (
                              <Checkbox checked={false} disabled />
                            )}{" "}
                            প্যান্ট
                            {measurement.Gharara ? (
                              <Checkbox checked={true} disabled />
                            ) : (
                              <Checkbox checked={false} disabled />
                            )}{" "}
                            ঘারারা
                            {measurement.Gown_bottom ? (
                              <Checkbox checked={true} />
                            ) : (
                              <Checkbox checked={false} />
                            )}{" "}
                            গাউন
                            <br></br>
                            <br></br>
                            <table className="2nd_td_dotted">
                              <tr>
                                <td>লম্বা :</td>
                                <td className="td_dotted">
                                  {measurement.Length_bottom
                                    ? measurement.Length_bottom
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>কোমর :</td>
                                <td className="td_dotted">
                                  {measurement.Waist_bottom
                                    ? measurement.Waist_bottom
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>হিপ :</td>
                                <td className="td_dotted">
                                  {measurement.Hip_bottom
                                    ? measurement.Hip_bottom
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>থাই :</td>
                                <td className="td_dotted">
                                  {measurement.Thigh ? measurement.Thigh : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>KNEE :</td>
                                <td className="td_dotted">
                                  {measurement.Knee ? measurement.Knee : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>মুহরি :</td>
                                <td className="td_dotted">
                                  {measurement.Leg_openning
                                    ? measurement.Leg_openning
                                    : ""}
                                </td>
                              </tr>
                            </table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                  </>
                );
              })}
            </div>
          </div>

          <Row
            style={{
              borderBottom: "2px solid lightgray",
              paddingBottom: "5px",
            }}
          >
            <Col
              span={9}
              style={{
                paddingTop: "45px",
              }}
            >
              INVOICE NO.{" "}
              {details.invoice_number ? <b>{details.invoice_number}</b> : ""}
            </Col>
            <Col span={6} style={{ textAlign: "center" }}>
              {renderImage()}
            </Col>
            <Col span={9} style={{ textAlign: "right", paddingTop: "25px" }}>
              {details.Contact[0].name ? <> {details.Contact[0].name}</> : ""}
              <br></br>
              ORDER NO.{" "}
              {details.order_number ? <b>{details.order_number}</b> : ""}
              <br></br>
              DELIVERY - <b>{details.delivery_date}</b>
              { }
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={18}>
              <table className="product_table">
                <tbody>
                  <tr style={{ fontWeight: "500" }}>
                    <td>NO</td>
                    <td>PRODUCT DETAILS</td>
                    <td>DESIGN CODE</td>

                    <td>QTY</td>
                  </tr>
                  {renderitems()}
                </tbody>
              </table>
            </Col>
            <Col span={6}>
              <Row style={{ textAlign: "center" }}>
                <Col span={24} style={{ marginTop: "5px" }}>
                  DELIVERY DATE
                  <span className="order_span">
                    {details.delivery_date ? details.delivery_date : "-"}
                  </span>
                </Col>
                <Col span={24} style={{ marginTop: "5px" }}>
                  PROGRAM DATE
                  <span className="order_span">
                    {details.program_date ? details.program_date : "-"}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          {measurements?.map((measurement) => {
            return (
              <>
                <Row>
                  <Col span={8}>
                    <Row>
                      <Col span={24}>
                        <h3>EXTRA ADDITIONS</h3>
                        <div
                          style={{
                            minHeight: "40vh",
                            border: "2px solid black",
                            padding: "10px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: measurement.Note,
                          }}
                        ></div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={16} style={{ paddingLeft: "10px" }}>
                    <h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: measurement.measuremnt_name,
                        }}
                      ></div>
                      MEASUREMENT DETAILS{" "}
                    </h3>
                    <Row
                      style={{
                        border: "2px solid black",
                        padding: "10px",
                      }}
                    >
                      <Col
                        span={15}
                        style={{ borderRight: "1px solid lightgray" }}
                      >
                        <h5>TOPS</h5>
                        {measurement.Blouse ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        ব্লাউজ
                        {measurement.Kameez ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        কামিজ
                        {measurement.Gown ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        গাউন
                        <br></br>
                        <br></br>
                        <Row>
                          <Col span={11}>
                            <table>
                              <tr>
                                <td>বডি :</td>
                                <td className="td_dotted1">
                                  {measurement.Chest ? measurement.Chest : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>কোমর :</td>
                                <td className="td_dotted1">
                                  {measurement.Waist ? measurement.Waist : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>হিপ :</td>
                                <td className="td_dotted1">
                                  {measurement.Hip ? measurement.Hip : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>কামিজ লম্বা :</td>
                                <td className="td_dotted1">
                                  {measurement.End ? measurement.End : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>সোল্ডার :</td>
                                <td className="td_dotted1">
                                  {measurement.Shoulder
                                    ? measurement.Shoulder
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>আর্মহোল :</td>
                                <td className="td_dotted1">
                                  {measurement.Arm_hole
                                    ? measurement.Arm_hole
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td> হাতা লম্বা :</td>
                                <td className="td_dotted1">
                                  {measurement.Sleeve_l
                                    ? measurement.Sleeve_l
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td> মাসেল :</td>
                                <td className="td_dotted1">
                                  {measurement.Muscle ? measurement.Muscle : ""}
                                </td>
                              </tr>
                            </table>
                          </Col>
                          <Col span={13}>
                            <br></br>
                            <table className="2nd_td_dotted">
                              <tr>
                                <td>মুহরি :</td>
                                <td className="td_dotted">
                                  {measurement.Hand_opening
                                    ? measurement.Hand_opening
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>লম্বা :</td>
                                <td className="td_dotted">
                                  {measurement.Length ? measurement.Length : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>কামিজ ফাড়া:</td>
                                <td className="td_dotted">
                                  {measurement.Slit ? measurement.Slit : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>গলার ডিপ (F) :</td>
                                <td className="td_dotted">
                                  {measurement.Neck_deep_f
                                    ? measurement.Neck_deep_f
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>গলার ডিপ (B) :</td>
                                <td className="td_dotted">
                                  {measurement.Neck_deep_b
                                    ? measurement.Neck_deep_b
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td>হাফ বডি</td>
                                <td className="td_dotted">
                                  {measurement.Half_body
                                    ? measurement.Half_body
                                    : ""}
                                </td>
                              </tr>
                            </table>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} offset={1}>
                        <h5>Bottom</h5>
                        {measurement.Skirt ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        স্কার্ট
                        {measurement.Paladzo ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        প্লাজো
                        <br></br>
                        {measurement.Pant ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        প্যান্ট
                        {measurement.Gharara ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        ঘারারা
                        {measurement.Gown_bottom ? (
                          <Checkbox checked={true} disabled />
                        ) : (
                          <Checkbox checked={false} disabled />
                        )}{" "}
                        গাউন
                        <br></br>
                        <br></br>
                        <table className="2nd_td_dotted">
                          <tr>
                            <td>লম্বা :</td>
                            <td className="td_dotted">
                              {measurement.Length_bottom
                                ? measurement.Length_bottom
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>কোমর :</td>
                            <td className="td_dotted">
                              {measurement.Waist_bottom
                                ? measurement.Waist_bottom
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>হিপ :</td>
                            <td className="td_dotted">
                              {measurement.Hip_bottom
                                ? measurement.Hip_bottom
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>থাই :</td>
                            <td className="td_dotted">
                              {measurement.Thigh ? measurement.Thigh : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>KNEE :</td>
                            <td className="td_dotted">
                              {measurement.Knee ? measurement.Knee : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>মুহরি :</td>
                            <td className="td_dotted">
                              {measurement.Leg_openning
                                ? measurement.Leg_openning
                                : ""}
                            </td>
                          </tr>
                        </table>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />
              </>
            );
          })}
        </>
      );
    }
  };

  return (
    <>
      <>
        <a href="#" onClick={showDrawer} style={{ margin: 4 }}>
          Quick View
        </a>
        <Drawer
          width="850"
          onClose={onClose}
          visible={visible}
          placement="right"
        // bodyStyle={{ paddingBottom: 80 }}
        >
          {renderData()}
        </Drawer>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
    brandlist: state.brand.brandlist,
  };
};

export default connect(mapStateToProps, {
  getInvoiceItem,
  getServices,
  getBusinessProfile,
  getInvoiceMeasurement,
  getSpecificLocation,
  getSpecificInvoice,
})(Quickview);
