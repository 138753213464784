import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";


import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Button,
  Col,
  Row,
  Select,
  message,
  TreeSelect,
  Divider,
  Drawer,
} from "antd";

const { Option } = Select;

const BarcodePrinter = ({ details, variation }) => {
  const wrapper_ref = React.useRef();
  const componentRef = useRef();

  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "BDT",
  });

  const printt = () => {
    const opt = {
      scale: 4,
    };

    const elem = wrapper_ref.current;
    html2canvas(elem, opt).then((canvas) => {
      const iframe = document.createElement("iframe");
      iframe.name = "printf";
      iframe.id = "printf";
      iframe.height = 0;
      iframe.width = 0;
      document.body.appendChild(iframe);

      const imgUrl = canvas.toDataURL({
        format: "jpeg",
        quality: "1.0",
      });
      let page = "";

      console.log(details);
      page += `<div style="min-height: 80vh;text-align: center; font-size: 8px; display: flex">
        <div style="width: 30%; padding: 20px">
          
          <img style="margin-top: 30px; margin-left:10px" width="100%" src="${process.env.PUBLIC_URL + "/Elordark.png"}"/> 
        </div>
        <div style="padding: 0px;width: 33%; ">
            
            <img style="margin-top: 5px" width="100%" src="${imgUrl}"/> 
           
            <h3 style="margin-top: -15px;margin-bottom: 0px;font-size: 25px">${formatter.format(variation.selling_price
      )}</h3>
          </div>
        <div style="width: 33%; ">
        
        </div>
        </div>`;


      var newWin = window.frames["printf"];
      newWin.document.write(
        `<body onload="window.print()"><style>
        @page { size: auto;  margin: 0mm; }
        </style><table>${page}<table></body>`
      );
      newWin.document.close();
      return page;
    });
  };
  console.log(details);
  // <h3 style="margin: auto;font-size: 10px">${details.brand}</h3>
  // <h3 style="margin-top: -20px;margin-bottom: 0px; font-size: 20px">${details.category_name}</h3>
  return (

    <>
      <div ref={wrapper_ref}>
        <Barcode value={variation.barcode} />
      </div>

      {/* <div style={{ minHeight: "80vh", textAlign: "center", fontSize: "8px", display: "flex" }}>
        <div style={{ width: "33%" }}>
          <h3 style={{ marginTop: "20px", marginBottom: "10px", fontSize: "10px" }}>${details.brand}</h3>
        </div>
        <div style={{ padding: "0px", width: "33%" }}>
          <h3 style={{ marginTop: "20px", marginBottom: "10px", fontSize: "10px" }}>${details.brand}</h3>
          <h4 style={{ margin: "0px", marginBottom: "10px", fontSize: "8px" }}>${details.breadcrumbs
            ? details.breadcrumbs.length > 1
              ? details.breadcrumbs[details.breadcrumbs.length - 2].name
              : details.breadcrumbs[details.breadcrumbs.length - 1].name
            : ""
          }</h4>

          IMAGE

          ${details.category_name
          }

          <h4 style={{ margin: "0px", marginBottom: "10px", fontSize: "10px" }}>${details.title
          }</h4>


          <h3 style={{ marginTop: "1px", marginBottom: "1px", fontSize: "10px" }}>${formatter.format(
            variation.selling_price
          )}</h3>
          <h4 style={{ marginTop: "1px", marginBottom: "1px" }}>(VAT inclusive)</h4>
          <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>**Dry wash only</h4>
        </div>
        <div style={{ width: "33%" }}>
        </div>
      </div> */}
      <Button onClick={printt}>Print Barcode</Button>
    </>
  );
};

export default connect()(BarcodePrinter);
