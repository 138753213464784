import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getInvoiceMeasurement,
  updateMasurement,
  getSpecificMeasurement,
  createMeasurement,
} from "../../../../actions/measurment";
import { getSpecificMeasurementbycontact } from "../../../../actions/measurment";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Drawer,
  Button,
  Col,
  Row,
  Select,
  message,
  Divider,
  Affix,
  Skeleton,
  Radio,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const EditMeasurement = ({
  getInvoiceMeasurement,
  getSpecificMeasurement,
  getSpecificMeasurementbycontact,
  updateMasurement,
  createMeasurement,
  details,
  update,
  product,
  invoice,
  setservicetrigger,
  setreload,
  service,
  brandlist,
}) => {
  const initial = { Note: "" };
  const [visible, setVisible] = useState(false);
  const [showbutton, setshowbutton] = useState(false);
  const [sleeveVisible, setSleeveVisible] = useState(false);
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm();

  const showDrawer = () => {
    if (update == 1) {
      getSpecificMeasurement(details.id).then((result) => {
        setSleeveVisible(result.Sleeve_less);
        console.log(result);
        setshowbutton(true);
        setdata(result);
        form.setFieldsValue(result);
        setloading(false);
        setVisible(true);
      });
    } else {
      console.log(details);
      form.setFieldsValue(details);
      setloading(false);
      setVisible(true);
    }
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    // measurements.current[0] = values;
    if (update == 1) {
      updateMasurement(data.id, values).then((res) => {
        setVisible(false);
        setservicetrigger(true);
        setreload(true);
      });
    } else {
      if (typeof product != "undefined") {
        values["product"] = product;
      }
      if (typeof service != "undefined") {
        values["service"] = service;
      }
      values["invoice"] = invoice;
      values["data"] = "";
      createMeasurement(values).then((res) => {
        setVisible(false);
        setservicetrigger(true);
        setreload(true);
      });
    }
  };

  const renderDrawer = () => {
    if (loading) {
      //   return <Skeleton active />;
    } else {
      return (
        <Drawer
          title="Measurement details"
          width={"80%"}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            initialValues={initial}
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 16,
            }}
            labelAlign="left"
          >
            <Row>
              <Form.Item
                name="Type"
                label={<p className="font-bold">Measurement Type</p>}
              // className="font-bold"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please pick an item!",
              //   },
              // ]}
              // width={300}
              >
                <Radio.Group style={{ width: "160px" }}>
                  <Radio value="dress">Dress</Radio>
                  <Radio value="body">Body</Radio>
                </Radio.Group>
              </Form.Item>
            </Row>

            <Row gutter={16}>
              <Col sm={24} md={10}>
                <h3>TOPS</h3>
                <Row>
                  <Form.Item name="Blouse" valuePropName="checked">
                    <Checkbox>{brandlist[0]?.name == "ANZARA" ? "BLOUSE" : "KOTI"}</Checkbox>
                  </Form.Item>
                  <Form.Item name="Kameez" valuePropName="checked">
                    <Checkbox>{brandlist[0]?.name == "ANZARA" ? "KAMEEZ" : "SHERWANI"}</Checkbox>
                  </Form.Item>
                  <Form.Item name="Gown" valuePropName="checked">
                    <Checkbox>{brandlist[0]?.name == "ANZARA" ? "GOWN" : "PANJABI"}</Checkbox>
                  </Form.Item>
                </Row>
                <Row>
                  <Col span={11}>
                    {brandlist[0]?.name == "ANZARA" ?
                      <Form.Item
                        name="Chest"
                        label="Chest"
                      >
                        <Input />
                      </Form.Item>
                      : null}
                    {brandlist[0]?.name == "AZRAN" ?
                      <Form.Item
                        name="body"
                        label="Body"
                      >
                        <Input />
                      </Form.Item>
                      : null}
                    <Form.Item
                      name="Waist"
                      label="Waist"
                    >
                      <Input />
                    </Form.Item>
                    {brandlist[0]?.name == "ANZARA" ?

                      <Form.Item
                        name="Hip"
                        label="Hip"
                      >
                        <Input />
                      </Form.Item>
                      : null}
                    {brandlist[0]?.name == "ANZARA" ?

                      <Form.Item name="End" label="End">
                        <Input />
                      </Form.Item>
                      : null}
                    <Form.Item name="Shoulder" label="Shoulder">
                      <Input />
                    </Form.Item>
                    <Form.Item name="Arm_hole" label="Arm hole">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="Sleeve_less"
                      label="Sleeve Less"
                      width={300}
                    >
                      <Radio.Group style={{ width: "300px" }}>
                        <Radio
                          value={true}
                          onClick={() => {
                            setSleeveVisible(true);
                          }}
                        >
                          Yes
                        </Radio>
                        <Radio
                          defaultChecked
                          value={false}
                          onClick={() => {
                            setSleeveVisible(false);
                          }}
                        >
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    {!sleeveVisible ? (
                      <Form.Item
                        name="Sleeve_l"
                        label="Sleeve L."
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      ""
                    )}
                    <Form.Item name="Muscle" label="Muscle">
                      <Input />
                    </Form.Item>
                  </Col>


                  <Col span={11} offset={1}>
                    <Form.Item name="Hand_opening" label="Hand opening">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="Length"
                      label="Length"
                    >
                      <Input />
                    </Form.Item>
                    {brandlist[0]?.name == "ANZARA" ?

                      <Form.Item name="Slit" label="Slit">
                        <Input />
                      </Form.Item>
                      : null}
                    {brandlist[0]?.name == "ANZARA" ?

                      <Form.Item name="Neck_deep_f" label="Neck deep (f)">
                        <Input />
                      </Form.Item>
                      : null}
                    {brandlist[0]?.name == "ANZARA" ?

                      <Form.Item name="Neck_deep_b" label="Neck deep (b)">
                        <Input />
                      </Form.Item>
                      : null}
                    {brandlist[0]?.name == "ANZARA" ?

                      <Form.Item name="Half_body" label="Half Body">
                        <Input />
                      </Form.Item>
                      : null}
                    {brandlist[0]?.name == "AZRAN" ?

                      <Form.Item name="mala" label="Mala">
                        <Input />
                      </Form.Item>
                      : null}
                    {brandlist[0]?.name == "AZRAN" ?

                      <Form.Item name="pagri" label="pagri">
                        <Input />
                      </Form.Item>
                      : null}
                  </Col>
                </Row>
              </Col>
              <Col
                sm={24} md={7}
                style={{
                  borderRight: "1px solid #F0F0F0",
                  borderLeft: "1px solid #F0F0F0",
                  padding: "0 25px",
                }}
              >
                <h3>BOTTOM</h3>
                <Row>
                  <Form.Item name="Skirt" valuePropName="checked">
                    <Checkbox>{brandlist[0]?.name == "ANZARA" ? "SKIRT" : "PAJAMA"}</Checkbox>
                  </Form.Item>
                  <Form.Item name="Paladzo" valuePropName="checked">
                    <Checkbox>{brandlist[0]?.name == "ANZARA" ? "PALADZO" : "CHURIDAR"}</Checkbox>
                  </Form.Item>
                  <Form.Item name="Pant" valuePropName="checked">
                    <Checkbox>Pant</Checkbox>
                  </Form.Item>
                  {brandlist[0]?.name == "ANZARA" ? <>
                    <Form.Item name="Gharara" valuePropName="checked">
                      <Checkbox>Gharara</Checkbox>
                    </Form.Item>
                    <Form.Item name="Gown_bottom" valuePropName="checked">
                      <Checkbox>Gown</Checkbox>
                    </Form.Item>
                  </> : null}
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="Length_bottom"
                      label="Length"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="Waist_bottom"
                      label="Waist"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="Hip_bottom"
                      label="Hip"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="Thigh" label="Thigh">
                      <Input />
                    </Form.Item>
                  </Col>
                  {brandlist[0]?.name == "ANZARA" ? <>
                    <Col span={24}>
                      <Form.Item name="Knee" label="Knee">
                        <Input />
                      </Form.Item>
                    </Col></> : null}
                  <Col span={24}>
                    <Form.Item name="Leg_openning" label="Leg openning">
                      <Input />
                    </Form.Item>
                  </Col>

                </Row>
              </Col>
              <Col
                sm={24} md={7}
                style={{
                  padding: "0 25px",
                }}
              >
                <h3>EXTRA NOTE</h3>
                <Form.Item
                  name="Note"
                  label=""
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{ minHeight: "60vh" }}
                >
                  <ReactQuill theme="snow" style={{ height: "60vh" }} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <Form.Item>
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      );
    }
  };

  return (
    <>
      <a href="#" onClick={showDrawer} style={{ margin: 4 }}>
        {update == 1 ? "Edit measurements" : "Add measurements"}
      </a>
      {renderDrawer()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    brandlist: state.brand.brandlist,
  };
};

export default connect(mapStateToProps, {
  getInvoiceMeasurement,
  updateMasurement,
  getSpecificMeasurement,
  createMeasurement,
  getSpecificMeasurementbycontact,
})(EditMeasurement);
