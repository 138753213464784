import backend from "../api/api";
import { store } from "../store";

const getConfig = () => {
    const token = store.getState().auth.token;
    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };
    return config;
};

export const createRefund = (formValues) => async (dispatch) => {
    formValues["data"] = "";
    try {
        const response = await backend.post(
            "api/order/refund/",
            { ...formValues },
            getConfig()
        );
        if (response.status === 201) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getAllRefund = (keyword = "", start = "", end = "", invoice__location__id = "", pageno = 1, page_size = 10) => async (dispatch) => {
    try {
        const response = await backend.get(`api/order/refund/?page=${pageno}&page_size=${page_size}&keyward=${keyword}&start=${start}&end=${end}&invoice__location__id=${invoice__location__id}`, getConfig());
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getSpecificRefund = (id) => async (dispatch) => {
    try {
        const response = await backend.get(`api/order/refund/${id}/`, getConfig());
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const deleteRefund = (id) => async (dispatch) => {
    try {
        const response = await backend.delete(
            `api/order/refund/${id}/`,
            getConfig()
        );
        if (response.status === 204) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const updateRefund = (id, formValues) => async (dispatch) => {
    try {
        const response = await backend.patch(
            `api/order/refund/${id}/`,
            formValues,
            getConfig()
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
    }
};

